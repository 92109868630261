var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.some(function (ref) {
	var href = ref.href;

	return !!href;
}))?_c('ul',{staticClass:"tw-hidden tw-bg-white tw-min-w-full tw-absolute tw-top-full tw-left-0 tw-border tw-border-snow tw-list-none tw-p-0 group-hover:tw-inline-block"},[_vm._l((_vm.data),function(ref){
var href = ref.href;
var name = ref.name;
var label = ref.label;
var code = ref.code;
return [(href)?_c('li',{key:name},[_c('RouterLink',{staticClass:"tw-block tw-p-4 tw-whitespace-nowrap hover:tw-bg-snow tw-no-underline",attrs:{"to":href,"data-item":code}},[_vm._v(" "+_vm._s(label)+" ")])],1):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import { Data, Entry } from '../../types/navigation';
import { debounce } from '../../assets/js/utilities/debounce';
import { ContentNavigationProps } from './ContentNavigation.props';
import ContentNavigationDropdown from './partials/ContentNavigation__Dropdown.vue';
import ContentNavigationList from './partials/ContentNavigation__List.vue';
import ContentNavigationTrigger from './partials/ContentNavigation__Trigger.vue';

// Constant(s) -------------------------------------------------------------------------------------

const SEARCH_KEY = 'href';

// Function(s) -------------------------------------------------------------------------------------

function shouldBeRendered(item: Entry) {
	if (item[SEARCH_KEY]) {
		return true;
	}

	if (item.children && item.children.length) {
		return item.children.some((child) => child[SEARCH_KEY]);
	}

	return false;
}

function find(searchTerm: string, parent: Entry | null, item: Entry): Data | undefined {
	if (item[SEARCH_KEY]?.toLowerCase() === searchTerm.toLowerCase()) {
		return { current: item, siblings: parent?.children };
	}

	if (item.children) {
		for (const child of item.children) {
			const found = find(searchTerm, item, child);

			if (found) {
				return found;
			}
		}
	}
}

function getHorizontalGap(element: Element) {
	return getComputedStyle(element)
		.margin.split('px ')
		.filter((_, index) => index % 2 === 1)
		.reduce((total, current) => total + parseInt(current), 0);
}

function getWidthWithGap(element: Element) {
	return element.clientWidth + getHorizontalGap(element);
}

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'ContentNavigation',
	components: { ContentNavigationDropdown, ContentNavigationList, ContentNavigationTrigger },
	props: ContentNavigationProps,
	data() {
		return {
			currentWidth: 0,
			siblingsWidth: [] as number[],
			moreWidth: 0,
			siblingsToShow: [] as Entry[],
			morePages: [] as Entry[],
			isLoaded: false,
			lastLink: '',
			debouncedResizeHandler: null as EventListener | null,
		};
	},
	computed: {
		pages(): Entry[] {
			return this.data.filter(shouldBeRendered);
		},
		currentPage(): Data | undefined {
			let tmp;

			for (const page of this.pages) {
				tmp = find(this.searchTerm, null, page);

				if (tmp) break;
			}

			return tmp;
		},
		current(): Entry | undefined {
			return this.currentPage?.current;
		},
		siblings(): Entry[] {
			return (this.currentPage?.siblings || this.pages).filter(
				(item: Entry) => item[SEARCH_KEY] !== this.searchTerm,
			);
		},
		searchTerm(): string {
			return decodeURI(this.$route.path);
		},
	},
	watch: {
		searchTerm() {
			this.unload();
		},
		isLoaded(newValue) {
			if (!newValue) {
				this.updateSiblingsWidth();
			}
		},
	},
	created() {
		this.siblingsToShow = this.siblings;
	},
	mounted() {
		this.updateSiblingsWidth();
		this.listenToResize(true);
	},
	beforeDestroy() {
		this.listenToResize(false);
	},
	methods: {
		setNavigationElements() {
			const module = this.$refs.contentNavigation as HTMLElement;
			const moduleWidth = module.clientWidth;
			let siblingsTotalWidth = this.siblingsWidth.reduce((total, current) => total + current, 0);
			let sliceAmount = 0;

			for (const siblingWidth of this.siblingsWidth) {
				if (moduleWidth < this.currentWidth + siblingsTotalWidth + this.moreWidth) {
					siblingsTotalWidth -= siblingWidth;
					sliceAmount += 1;
				} else {
					break;
				}
			}

			const siblingIndex = this.siblings.length - sliceAmount;

			this.siblingsToShow = this.siblings.slice(0, siblingIndex);
			this.morePages = this.siblings.slice(siblingIndex);
		},
		handleOutsideClick(event: Event) {
			const link = event.target as HTMLAnchorElement;

			if (link.href !== this.lastLink) {
				window.removeEventListener('click', this.handleOutsideClick);

				this.lastLink = '';
			}
		},
		handleTriggerClick(event: Event) {
			const link = event.currentTarget as HTMLAnchorElement;

			event.preventDefault();

			if (!this.BREAKPOINT.IS_DESKTOP) {
				if (link.href !== this.lastLink && link.nextElementSibling) {
					window.addEventListener('click', this.handleOutsideClick);

					this.lastLink = link.href;

					return undefined;
				}
			}

			if (link.href) {
				const { pathname } = new URL(link.href);

				this.$router.push(pathname);
			}
		},
		handleWindowResize() {
			requestAnimationFrame(() => this.setNavigationElements());
		},
		unload() {
			this.siblingsToShow = this.siblings;
			this.isLoaded = false;
		},
		updateSiblingsWidth() {
			const module = this.$refs.contentNavigation as HTMLElement;

			if (module) {
				const childs = Array.from(module.children);
				const current = (this.$refs.currentDropdown as Vue)?.$el;
				const siblings = current ? childs.slice(1, -1) : childs.slice(0, -1);
				const more = childs[childs.length - 1];

				this.currentWidth = current ? getWidthWithGap(current) : 0;
				this.siblingsWidth = siblings.map((child) => getWidthWithGap(child)).reverse();
				this.moreWidth = getWidthWithGap(more);

				this.setNavigationElements();

				this.isLoaded = true;
			}
		},
		listenToResize(listen: boolean): void {
			if (!process.client) return;
			if (listen) {
				if (!this.debouncedResizeHandler) {
					this.debouncedResizeHandler = debounce(this.handleWindowResize, 250);
				}
				window.addEventListener('resize', this.debouncedResizeHandler);
			} else {
				window.removeEventListener('resize', this.debouncedResizeHandler as EventListener);
			}
		},
	},
});

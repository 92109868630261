import { render, staticRenderFns } from "./newsletter-subscribe-page.vue?vue&type=template&id=2db5c616&scoped=true&"
import script from "./newsletter-subscribe-page.ts?vue&type=script&lang=ts&"
export * from "./newsletter-subscribe-page.ts?vue&type=script&lang=ts&"
import style0 from "./newsletter-subscribe-page.scss?vue&type=style&index=0&id=2db5c616&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db5c616",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Breadcrumb/Breadcrumb.vue').default,ContentNavigation: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ContentNavigation/ContentNavigation.vue').default,Media: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Media/Media.vue').default,GenericFormWrap: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/forms/GenericFormWrap/GenericFormWrap.vue').default})




















import Vue, { PropType } from 'vue';
import { Entry } from '../../../types/navigation';

export default Vue.extend({
	props: {
		data: { type: Array as PropType<Entry[]>, required: true },
	},
});














import Vue from 'vue';

export default Vue.extend({
	props: {
		code: { type: String, required: true },
		label: { type: String, required: true },
		href: { type: String, default: null },
		hasIcon: { type: Boolean, default: null },
	},
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.length)?_c('div',{ref:"contentNavigation",class:['tw-hidden', 'md:tw-flex', { 'tw-overflow-hidden': !_vm.isLoaded }],attrs:{"data-name":"content-navigation"}},[(_vm.current)?_c('ContentNavigationDropdown',{ref:"currentDropdown",staticClass:"tw-mr-3"},[_c('ContentNavigationTrigger',_vm._b({staticClass:"tw-font-bold",on:{"click":_vm.handleTriggerClick}},'ContentNavigationTrigger',{
				code: _vm.current.code,
				label: _vm.current.label,
				href: _vm.current.href,
				hasIcon: _vm.current.children && _vm.current.children.some(function (ref) {
					var href = ref.href;

					return !!href;
}),
			},false)),(_vm.current.children)?_c('ContentNavigationList',{attrs:{"data":_vm.current.children}}):_vm._e()],1):_vm._e(),_vm._l((_vm.siblings),function(sibling,index){return [(!!sibling.href || (sibling.children && sibling.children.some(function (ref) {
				var href = ref.href;

				return !!href;
			})))?_c('ContentNavigationDropdown',{directives:[{name:"show",rawName:"v-show",value:(
				!_vm.isLoaded || _vm.siblingsToShow.some(function (siblingToShow) { return siblingToShow.code === sibling.code; })
			),expression:"\n\t\t\t\t!isLoaded || siblingsToShow.some((siblingToShow) => siblingToShow.code === sibling.code)\n\t\t\t"}],key:sibling.name,class:{ 'tw-ml-3': index }},[_c('ContentNavigationTrigger',_vm._b({on:{"click":_vm.handleTriggerClick}},'ContentNavigationTrigger',{
					code: sibling.code,
					label: sibling.label,
					href: sibling.href,
					hasIcon: sibling.children && sibling.children.some(function (ref) {
						var href = ref.href;

						return !!href;
			}),
				},false)),(sibling.children)?_c('ContentNavigationList',{attrs:{"data":sibling.children}}):_vm._e()],1):_vm._e()]}),_c('ContentNavigationDropdown',{staticClass:"tw-ml-3",class:!_vm.morePages.length && ['cls-hidden', 'tw-pointer-events-none']},[_c('ContentNavigationTrigger',{attrs:{"code":"more","label":String(_vm.$t(_vm.$i18nKeys.contentNavigation.more)),"has-icon":""},on:{"click":_vm.handleTriggerClick}}),_c('ContentNavigationList',{attrs:{"data":_vm.morePages}})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
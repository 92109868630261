import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Entry } from '../../types/navigation';

// Model -------------------------------------------------------------------------------------------

export interface ContentNavigationModel {
	data: Entry[];
}

// Props -------------------------------------------------------------------------------------------

export const ContentNavigationProps: Props<ContentNavigationModel> = {
	data: {
		type: Array as PropType<ContentNavigationModel['data']>,
		required: true,
	},
};

import Vue from 'vue';
import { MediaModel } from '@/node_modules/@osp/design-system/components/Media/Media.props';
import { ContentPageMixin } from '../content-page/content-page-mixin';
import { backend } from '@/@api/backend';
import { postJson } from '@/app-utils/http';
import { calculateImageUrl } from '@/assets/js/images';
import { NewsletterSubscribeRequest } from '@/generated/hybris-raml-api';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { MessageboxType } from '~/@api/store.types';
import BasePage from '~/pages/base-page/base-page';

// Page --------------------------------------------------------------------------------------------

export default Vue.extend({
	extends: BasePage,
	mixins: [ContentPageMixin],
	computed: {
		isStructuredContent() {
			return true;
		},
		mediaData(): MediaModel {
			return {
				media: {
					src: calculateImageUrl(
						this.$store,
						{ assetId: this.$t(this.$i18nKeys.newsletterSubscribePage.image) },
						{ preset: 'textpictureteaser_headline-1x', width: 1280 },
					),
					sources: [],
					alt: '',
				},
				aspectRatio: { width: 16, height: 7 },
			};
		},
	},
	methods: {
		async handleSubmitGenericFormWrap(data: NewsletterSubscribeRequest) {
			const response = await postJson(
				backend.API.V2.NEWSLETTER.SUBSCRIBE(this.$store),
				{
					...data,
					gender: {
						code: data.gender,
					},
					language: useServerContextStore(this.$store).state.session.language,
				},
				this.$store,
			);

			if (response.ok) {
				useMessageboxStore(this.$store).api.addMessage({
					global: true,
					dismissible: true,
					key: 'backend',
					text: this.$t(this.$i18nKeys.newsletterSubscribePage.successMessage),
					type: MessageboxType.SUCCESS,
				});
			}
		},
	},
});
